import * as React from "react"
import styled from "@emotion/styled"
import { StaticImage } from "gatsby-plugin-image"
import { Global, css } from "@emotion/react"
import { Helmet } from "react-helmet"

// styles
const pageStyles = {
  color: "#232129",
  fontFamily: "-apple-system, Roboto, sans-serif, serif",
}

const Content = styled.div`
  display: flex;
  flex-direction: column;
  padding: 20px;
  width: 100%;
  min-height: 100vh;
  box-sizing: border-box;
  justify-content: center;
  align-items: center;
`
const Photo = styled.div`
  max-width: 230px;
  max-height: 230px;
`
const ContentBlock = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`
const RightPart = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 20px 0;
  align-items: center;
`
const Name = styled.h2`
  margin-top: 0;
  margin-bottom: 5px;
  font-family: 'Open Sans', sans-serif;
  font-weight: 300;
  font-size: 26px;
`
const Title = styled.div`
  color: #888888;
  font-size: 18px;
  font-family: 'Open Sans', sans-serif;
  font-weight: 300;
  margin-bottom: 10px;
`
const Description = styled.div`
  font-family: 'Open Sans', sans-serif;
  font-size: 16px;
  margin-bottom: 10px;
  text-align: center;
`
const Actions = styled.div`
`
const Link = styled.a`
  color: #666666;
  text-decoration: underline;
  cursor: pointer;
  font-size: 16px;
  margin-right: 10px;
  font-weight: 300;
`
const GlobalStyles = css`
  body, html {
    padding:0;
    margin:0;
  }
  
  body {
  }
`

// markup
const IndexPage = () => {
  return (
    <main style={pageStyles}>
      <Global styles={GlobalStyles} />
      <Helmet>
        <title>Arseny Yankovski, Software Architect</title>
        <meta property="og:title" content="Arseny Yankovski" />
        <meta property="og:description" content="Software Architect passionate about building software that helps people." />
        <meta property="og:image" content="https://arseny.dev/og.png" />
        <meta name="description" content="Software Architect passionate about building software that helps people." />
      </Helmet>
      <Content>
        <ContentBlock>
          <Photo>
            <StaticImage
              css={css`
              border-radius: 50%;
              border: 1px solid #EEEEEE;
            `}
              src="../images/arseny.jpg"
              alt="Arseny"
              imgStyle={{ borderRadius: '50%' }}
            />
          </Photo>
          <RightPart>
            <Name>Arseny Yankovski</Name>
            <Title>Software Architect</Title>
            <Description>My passion is building software that helps people.</Description>
            <Actions>
              <Link href="https://arseny.dev/Arseny_Yankovsky_CV_Resume.pdf" target="_blank">Download CV</Link>
              <Link href="https://github.com/ArsenyYankovsky" target="_blank">GitHub</Link>
              <Link href="https://www.linkedin.com/in/arseny-yankovsky/" target="_blank">LinkedIn</Link>
            </Actions>
          </RightPart>
        </ContentBlock>
      </Content>
    </main>
  )
}

export default IndexPage
